<template>
  <section>
    hi
    <div>
      <RouterLink to="/get">
        Go to get
      </RouterLink>
    </div>
    <div>
      Cookie: {{ testStoreCookie }}
    </div>
    <div>
      LocalStorage: {{ testStoreLocalStorage }}
    </div>
    <div>
      SessionStorage: {{ testStoreSessionStorage }}
    </div>
    <div>
      IndexedDB: {{ testStoreIndexedDB }}
    </div>
  </section>
</template>

<script>
import '@/css/main.scss';

export default {
  name: 'SaveMain',
  data () {
    return {
      testStoreCookie: null,
      testStoreLocalStorage: null,
      testStoreSessionStorage: null,
      testStoreIndexedDB: null,
    };
  },
  computed: {
    deviceType () {
      return this.$store.state.deviceType;
    },
  },
  mounted () {
    this.saveData();
    // this.loadStoredData();
  },
  methods: {
    setCookie (name, value, days) {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie (name) {
      let nameEQ = name + "=";
      let ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    setIndexedDB () {
      let request = indexedDB.open('testDB', 1);

      request.onupgradeneeded = function (event) {
        let db = event.target.result;
        if (!db.objectStoreNames.contains('store')) {
          db.createObjectStore('store', { keyPath: 'key' });
        }
      };

      request.onsuccess = (event) => {
        let db = event.target.result;
        let transaction = db.transaction(['store'], 'readwrite');
        let objectStore = transaction.objectStore('store');
        objectStore.put({ key: 'test_store', value: 'indexeddb_value' });
      };

      request.onerror = function (event) {
        console.error('IndexedDB error:', event.target.errorCode);
      };
    },
    getIndexedDB () {
      let request = indexedDB.open('testDB', 1);

      request.onsuccess = (event) => {
        let db = event.target.result;
        let transaction = db.transaction(['store'], 'readonly');
        let objectStore = transaction.objectStore('store');
        let getRequest = objectStore.get('test_store');

        getRequest.onsuccess = (event) => {
          this.testStoreIndexedDB = event.target.result ? event.target.result.value : null;
        };
      };

      request.onerror = function (event) {
        console.error('IndexedDB error:', event.target.errorCode);
      };
    },
    loadStoredData () {
      // Cookie
      this.testStoreCookie = this.getCookie('test_store');

      // LocalStorage
      this.testStoreLocalStorage = localStorage.getItem('test_store');

      // SessionStorage
      this.testStoreSessionStorage = sessionStorage.getItem('test_store');

      // IndexedDB
      this.getIndexedDB();
    },
    saveData () {
      // Save data to all storage
      this.setCookie('test_store', 'cookie_value', 7);
      localStorage.setItem('test_store', 'localstorage_value');
      sessionStorage.setItem('test_store', 'sessionstorage_value');
      this.setIndexedDB();
    }
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>